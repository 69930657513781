import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title?: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

@Component({
  selector: 'slm-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: ConfirmDialogData,
    private readonly dlgRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  ngOnInit() {
  }

  get title(): string {
    return this.data.title || 'Confirm';
  }

  get message(): string {
    return this.data.message;
  }

  get confirmLabel(): string {
    return this.data.confirmLabel || 'Confirm';
  }

  get cancelLabel(): string {
    return this.data.cancelLabel || 'Cancel';
  }

  close(value?: unknown): void {
    this.dlgRef.close(value);
  }

}
