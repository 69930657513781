import * as leaflet from 'leaflet';
import { InjectionToken, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GeoJsonObject } from 'geojson';

export const LAYERS = new InjectionToken<LayerFactory>('LAYERS');

export enum LayerType {
  Base,
  Overlay
}

export interface LayerFactory {
  readonly type: LayerType;
  build(): LayerDescription[];
}

export interface LayerDescription {
  name: string;
  layer: leaflet.Layer | Promise<leaflet.Layer>;
  enable?: boolean;
}

@Injectable()
export class BaseLayerFactory implements LayerFactory {
  readonly type = LayerType.Base;

  build() {
    return [
      {
        name: 'Open Street Map',
        layer: leaflet
          .tileLayer(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {
              attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            }
          ),
        enable: true
      },
      {
        name: 'World Physical',
        layer: leaflet
          .tileLayer(
            'https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}',
            {
              attribution: 'Tiles &copy; Esri &mdash; Source: US National Park Service',
              maxZoom: 8
            }
          )
      },
      {
        name: 'World Imagery',
        layer: leaflet
          .tileLayer(
            'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            {
              attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye,'
              + ' Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            }
          )
      },
      {
        name: 'Daily Imagery',
        layer: leaflet
          .tileLayer(
            'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_CorrectedReflectance_TrueColor/default/'
            + '/GoogleMapsCompatible_Level{maxZoom}/{z}/{y}/{x}.jpg',
            {
              attribution: 'Imagery provided by services from the Global Imagery Browse Services (GIBS), '
              + 'operated by the NASA/GSFC/Earth Science Data and Information System '
              + '(<a href="https://earthdata.nasa.gov">ESDIS</a>) '
              + 'with funding provided by NASA/HQ.',
              bounds: [[-85.0511287776, -179.999999975], [85.0511287776, 179.999999975]],
              minZoom: 1,
              maxZoom: 9
            }
          )
      },
      {
        name: 'MODIS Terra Bands 7-2-1 (water)',
        layer: leaflet
          .tileLayer(
            'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_CorrectedReflectance_Bands721/default//'
            + 'GoogleMapsCompatible_Level{maxZoom}/{z}/{y}/{x}.jpg',
            {
              attribution: 'Imagery provided by services from the Global Imagery Browse Services (GIBS), operated by '
              + 'the NASA/GSFC/Earth Science Data and Information System '
              + '(<a href="https://earthdata.nasa.gov">ESDIS</a>) with funding provided by NASA/HQ.',
              bounds: [[-85.0511287776, -179.999999975], [85.0511287776, 179.999999975]],
              minZoom: 1,
              maxZoom: 9
            }
          )
      }
    ];
  }
}

@Injectable()
export class GeneralOverlaysFactory implements LayerFactory {

  readonly type = LayerType.Overlay;

  build() {
    return [
      {
        name: 'Labels and Boundaries',
        layer: leaflet
          .tileLayer(
            'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}.png',
            {
              attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, '
              + '<a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; '
              + '<a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
              subdomains: 'abcd',
              minZoom: 0,
              maxZoom: 20
            }
          )
      },
      {
        name: 'Land Surface Temperature',
        layer: leaflet
          .tileLayer(
            'https://map1.vis.earthdata.nasa.gov/wmts-webmerc/MODIS_Terra_Land_Surface_Temp_Day/default/'
            + '/GoogleMapsCompatible_Level{maxZoom}/{z}/{y}/{x}.png',
            {
              attribution: 'Imagery provided by services from the Global Imagery Browse Services (GIBS), operated by '
              + 'the NASA/GSFC/Earth Science Data and Information System (<a href="https://earthdata.nasa.gov">ESDIS</a>) '
              + 'with funding provided by NASA/HQ.',
              bounds: [[-85.0511287776, -179.999999975], [85.0511287776, 179.999999975]],
              minZoom: 1,
              maxZoom: 7,
              opacity: 0.75
            }
          )
      },
      {
        name: 'Normalized Difference Vegetation Index (Sentinel/ESA)',
        layer: leaflet
          .tileLayer(
            `https://sentineldata.vito.be/mapcache/wmts/1.0.0/PROBAV_S10_TOC_NDVI/default/2019-03-21/g3857/{z}/{y}/{x}.png`,
            {
              opacity: 0.75,
              minZoom: 1,
              maxNativeZoom: 9,
              maxZoom: 11,
              // capabilities: {
              //     url: 'https://sentineldata.vito.be/wmts?service=WMTS&request=GetCapabilities',
              //     layerId: 'PROBAV_S10_TOC_NDVI'
              // }
            }
          )
      }
    ];
  }
}

@Injectable()
export class AsyncLayerFactory implements LayerFactory {

  constructor(private readonly http: HttpClient) {}

  readonly type = LayerType.Overlay;

  build() {
    return [
      {
        name: 'Micro Watersheds (KFW)',
        layer: this.http
          .get('assets/mws-kfw.geojson')
          .pipe(
            map(geojson => leaflet.geoJSON(
              geojson as GeoJsonObject,
              {
                style: {
                  weight: 0.5
                },
                onEachFeature: (feature, layer) => {
                  layer.bindTooltip(
                    `<strong>${feature.properties['mws_nm']}</strong> (Woreda: ${feature.properties['W_NAME']})`
                  );
                }
              }
            ))
          )
          .toPromise(),
        enable: true
      },
      {
        name: 'Woredas',
        layer: this.http
          .get('assets/woreda.geojson')
          .pipe(
            map(geojson => leaflet.geoJSON(
              geojson as GeoJsonObject,
              {
                style: {
                  weight: 0.5,
                  color: '#802000'
                },
                onEachFeature: (feature, layer) => {
                  layer.bindTooltip(
                    `<strong>${feature.properties['WOREDANAME']}</strong>`
                  );
                },
                pane: 'tilePane'
              }
            ))
          )
          .toPromise()
      }
    ];
  }
}
