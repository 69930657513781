import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash/fp';
import { Observable } from 'rxjs';
import { BaseDataBase, Region } from './sriis-types';
import { map, publishReplay, refCount } from 'rxjs/operators';


export enum BaseDataType {
  activeShape,
  constructedAsPerDesign,
  kebele,
  maintenance,
  microWatershed,
  procurementMethod,
  prtScheme,
  region,
  scheme,
  status,
  type,
  uaFunctioning,
  utilizedAsPlanned,
  watershed,
  woreda,
}


@Injectable()
export class BaseDataService {

  private readonly cache: { [key: string]: Observable<BaseDataBase[]> } = {};
  private regionsCache: Observable<Region[]>;

  constructor(private readonly http: HttpClient) { }


  getRegions(): Observable<Region[]> {
    return this
      .getLocationTree()
      .pipe(
        map((regions: BaseDataBase[]) => regions.map(r => ({ id: r.id, name: r.name })))
      );
  }

  getLocationTree(): Observable<Region[]> {
    return this.regionsCache
      || (this.regionsCache =
        this.http
          .get<Region[]>(`/sriis/base/locations`)
          .pipe(
            map(addLocationParentRefs),
            publishReplay(1),
            refCount()
          )
      );
  }

  getBaseData<T extends BaseDataBase>(type: BaseDataType): Observable<T[]> {
    const typeName = BaseDataType[type];

    return this.cache[typeName] as Observable<T[]>
      || (this.cache[typeName] = this.http
        .get<T[]>(`/sriis/base/${snakeCase(typeName)}`));
  }

  getActiveShapes() {
    return this.getBaseData(BaseDataType.activeShape);
  }

  getStatuses() {
    return this.getBaseData(BaseDataType.status);
  }

  getSchemes() {
    return this.getBaseData(BaseDataType.scheme);
  }
}

function addLocationParentRefs(regions: Region[]) {
  return regions.map(r => {
    r.woredas = r.woredas && r.woredas.map(w => {
      w.region = r;
      w.watersheds = w.watersheds && w.watersheds.map(ws => {
        ws.woreda = w;
        ws.microWatersheds = ws.microWatersheds && ws.microWatersheds.map(mws => {
          mws.watershed = ws;

          return mws;
        });

        return ws;
      });

      return w;
    });

    return r;
  });
}
