import { Component } from '@angular/core';
import { GlobalNavButtonsService, GlobalNavButtonDef } from './core/global-nav-buttons.service';
import { AuthenticationNotification } from './auth/authentication-notification';

@Component({
  selector: 'slm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  navButtons: ReadonlyArray<GlobalNavButtonDef>;
  readonly title = 'SLM-II Dacota';

  constructor(
    authNotify: AuthenticationNotification,
    navButtonService: GlobalNavButtonsService
  ) {
    authNotify.notification
      .subscribe(
        auth => this.navButtons =
          auth.type === AuthenticationNotification.EVENT_TYPE_AUTHENTICATED
            ? navButtonService.buttons
            : []
      );
  }
}
