import { TreeNursery } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'slm-treenursery',
  templateUrl: './treenursery.component.html',
  styleUrls: ['./treenursery.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TreenurseryComponent implements StructureTypeComponentBase, OnInit {

  model: TreeNursery;

  constructor() { }

  ngOnInit() {
  }

}
