import { AuthenticationResult, UserPermissions } from './auth.service';
import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { AuthenticationNotification } from './authentication-notification';

@Directive({
  selector: '[slmAuthorize]'
})
export class AuthorizeDirective {

  private hasView = false;

  constructor(
    private readonly auth: AuthenticationNotification,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) { }

  @Input()
  set slmAuthorize(permission: keyof UserPermissions | 'user') {
    this.auth
      .notification
      .subscribe(
        e => {
          const permissions = (e.detail as AuthenticationResult) && (e.detail as AuthenticationResult).permissions;

          if (
            e.type === AuthenticationNotification.EVENT_TYPE_AUTHENTICATED
            && permissions
            && (permission === 'user' || permissions[permission] || permissions.admin)
            && !this.hasView
          ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          } else if (
            (
              e.type === AuthenticationNotification.EVENT_TYPE_LOGOUT
              || !permissions
              || !(permission === 'user' || permissions[permission] || permissions.admin)
            )
            && this.hasView
          ) {
            this.viewContainer.clear();
            this.hasView = false;
          }
        }
      );
  }
}
