import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { AuthModule } from '../auth';
import { DacotaHttpInterceptor } from '../auth/dacota-http-interceptor';
import { CategorySelectionComponent } from './category-selection/category-selection.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { libraryNavButtonProvider } from './library-nav-button';
import { LibraryRoutingModule } from './library-routing.module';
import { LibraryViewComponent } from './library-view/library-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from '../core';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    LibraryViewComponent,
    CategorySelectionComponent,
    DocumentListComponent,
    DocumentDetailsComponent,
    DocumentUploadComponent
  ],
  imports: [
    AuthModule,
    CommonModule,
    CoreModule,
    FlexLayoutModule,
    FormsModule,
    LibraryRoutingModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DacotaHttpInterceptor,
      multi: true
    },
    libraryNavButtonProvider
  ]
})
export class LibraryModule { }
