import { GlobalNavButtonDef, GLOBAL_NAV_BUTTONS } from '../core/global-nav-buttons.service';
import { PiwigoConnectorService } from './piwigo-connector.service';
import { FactoryProvider } from '@angular/core';

export function piwigoNavButtonFactory(piwigo: PiwigoConnectorService) {
    const piwigoNavButton: GlobalNavButtonDef = {
        icon: 'photo_library',
        tooltip: 'Open photo gallery',
        action: () => piwigo.openPiwigo(),
        order: 50
    };

    return piwigoNavButton;
}

export const piwigoNavButtonProvider: FactoryProvider = {
    provide: GLOBAL_NAV_BUTTONS,
    useFactory: piwigoNavButtonFactory,
    deps: [PiwigoConnectorService],
    multi: true
};
