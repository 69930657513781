import { GlobalNavButtonDef, GLOBAL_NAV_BUTTONS } from '../core/global-nav-buttons.service';
import { ValueProvider } from '@angular/core';

export const sriisNavButtonProvider: ValueProvider = {
    provide: GLOBAL_NAV_BUTTONS,
    useValue: {
        icon: 'map',
        routerLink: '/sriis',
        tooltip: 'Work on data',
        order: 10
    } as GlobalNavButtonDef,
    multi: true
};
