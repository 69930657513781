import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty as emptyObservable, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Attachment } from './sriis-structure/types/attachment';

export interface NewAttachment {
  name?: string;
  data?: string | Blob;
  description?: string;
}

@Injectable()
export class AttachmentsService {

  constructor(private readonly http: HttpClient) { }

  getAttachmentsFor(sidOrCode: number | string) {
    return this.http
      .get<Attachment[]>(`/sriis/structure/${sidOrCode}/attachment`);
  }

  getPreview(sidOrCode: number | string, name: string) {
    return this.http
      .get(
        `/sriis/structure/${sidOrCode}/attachment/${name}/preview`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(e => { console.warn('Failed to fetch preview from server', e); return emptyObservable(); }),
      );
  }

  getBlob(sidOrCode: number | string, name: string) {
    return this.http
      .get(
        `/sriis/structure/${sidOrCode}/attachment/${name}`,
        { responseType: 'blob' }
      );
  }

  upload(sidOrCode: number | string, attachment: NewAttachment): Observable<Attachment> {
    const formData = new FormData();
    formData.append('content', attachment.data || '', attachment.name);
    if (attachment.description) {
      formData.append('description', attachment.description);
    }

    return this.http
      .post<Attachment[]>(`/sriis/structure/${sidOrCode}/attachment`, formData)
      .pipe(
        map(array => array[0])
      );
  }
}
