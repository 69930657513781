import { SriisService } from '../sriis.service';
import { Component, OnInit } from '@angular/core';
import { Region } from '../sriis-types';
import { BaseDataService } from '../base-data.service';
import { DownloadService } from '../../core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'slm-sriis-export',
  templateUrl: './sriis-export.component.html',
  styleUrls: ['./sriis-export.component.css']
})
export class SriisExportComponent implements OnInit {

  exportFormat: string;
  commonFieldsOnly = true;

  sheetInPreparation = false;
  selectionListInPreparation = false;

  filter: {
    region?: string
  } = { };

  readonly exportFormats = [
    { id: 'kml', label: 'Keyhole Markup Language (kml)'},
    { id: 'xlsx', label: 'Excel 2007+ (xlsx)' },
    { id: 'ods', label: 'OpenDocument SpreadSheet (ods)' },
    { id: 'xls', label: 'Excel 97–2003 (xls)' },
    { id: 'html', label: 'HTML' }
  ];

  regions: Region[];

  constructor(
    private readonly baseData: BaseDataService,
    private readonly downloadService: DownloadService,
    private readonly snack: MatSnackBar,
    private readonly sriis: SriisService
  ) { }

  ngOnInit() {
    this.baseData
      .getRegions()
      .subscribe(regions => this.regions = regions);
  }

  export(format: string, filter: any) {
    this.sheetInPreparation = true;

    this.sriis
      .exportAsBlob(format, filter, this.commonFieldsOnly)
      .subscribe({
        next: blob => {
          this.sheetInPreparation = false;
          this.downloadService.download(blob, `structures.${format}`);
        },
        error: err => this.snack.open(`Export has failed: ${err.message || err}`)
      });
  }

  exportStructureSelectionList() {
    this.selectionListInPreparation = true;

    this.sriis
      .exportStructureSelectList()
      .subscribe({
        next: (tabbed: Blob) => {
          this.selectionListInPreparation = false;
          this.downloadService.download(tabbed, 'wbss-structure-id.tab');
        },
        error: err => this.snack.open(`Export has failed: ${err.message || err}`)
      });
  }

}
