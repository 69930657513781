import { DashboardProvider, DashboardDescription } from './dashboard-provider';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { flatMap, shareReplay, mapTo, map, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth';
import { Injectable } from '@angular/core';
import { now } from 'moment';
import { Observable, of as observableOf } from 'rxjs';

interface SuperSetDashboardDesc {
  changed_by_name: string;
  changed_on: string;
  creator: string;
  dashboard_link: string;
  dashboard_title: string;
  id: number;
  modified: string;
  url: string;
}

interface DashboardResponse {
  count: number;
  label_columns: Record<string, string>;
  list_columns: string[];
  modelview_name: 'DashboardModelViewAsync';
  order_columns: string[];
  page: number | null;
  page_size: number | null;
  pks: number[];
  result: SuperSetDashboardDesc[];
}

@Injectable({
    providedIn: 'root'
})
export class SupersetDashboardProvider implements DashboardProvider {

  private static readonly SUPERSET_ROOT = 'https://superset.slm-2.de';
  private dashboardsQuery: Observable<DashboardDescription[]>;
  private lastLogin: number;

  constructor(
      private readonly auth: AuthService,
      private readonly http: HttpClient,
      private readonly sanitizer: DomSanitizer
  ) {
  }

  get dashboards(): Observable<DashboardDescription[]> {
    return this.dashboardsQuery || (this.dashboardsQuery = this
      .ensureLoggedIn()
      .pipe(
        flatMap(() => this.http.get<DashboardResponse>(
            `${SupersetDashboardProvider.SUPERSET_ROOT}/dashboardasync/api/read`,
            { withCredentials: true }
          )
        ),
        map((dashboardResponse: DashboardResponse) =>
          dashboardResponse.result.map(desc => ({
            id: '' + desc.id,
            label: desc.dashboard_title,
            provider: this
          }))
        ),
        shareReplay()
      )
    );
  }

  getLoadedDashboardUrl(name: string): Observable<SafeResourceUrl> {
    return this
      .ensureLoggedIn()
      .pipe(
        mapTo(this.sanitizer
          .bypassSecurityTrustResourceUrl(`${SupersetDashboardProvider.SUPERSET_ROOT}/superset/dashboard/${name}?standalone=true`)
        )
      );
  }

  private ensureLoggedIn() {
    if (this.lastLogin && now() - this.lastLogin < 31 * 24 * 60 * 60 * 1000) {
      return observableOf(null);
    }

    return this.http
      .get(`${SupersetDashboardProvider.SUPERSET_ROOT}/login/`, { responseType: 'text', withCredentials: true })
      .pipe(
        flatMap(response => {
          const match = /<input\s.*?\bname="csrf_token"\s.*?\bvalue="([^"]+)"/.exec(response);
          const csrf = match ? match[1] : null;
          if (!csrf) {
            return [null];
          }

          const form = new FormData();
          form.append('csrf_token', csrf);
          form.append('username', this.auth.username);
          form.append('password', this.auth.password || '');

          return this.http
            .post(`${SupersetDashboardProvider.SUPERSET_ROOT}/login/`, form, { responseType: 'text', withCredentials: true });
        }),
        tap(() => this.lastLogin = now()),
        mapTo(null)
      );
  }
}
