import { SriisService } from './sriis.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StructureBaseType } from './sriis-types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SriisStructureModelResolver implements Resolve<StructureBaseType> {
    constructor(private readonly sriis: SriisService) {}

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any>|Promise<any>|any {
        return this.sriis.getStructure(route.params.sid);
    }
}
