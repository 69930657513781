import { AuthenticationResult } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthenticationNotification {

    static readonly EVENT_TYPE_AUTHENTICATED = 'authenticated';
    static readonly EVENT_TYPE_LOGOUT = 'logout';

    private readonly subject: BehaviorSubject<CustomEvent>;

    constructor() {
        this.subject = new BehaviorSubject(new CustomEvent(
            AuthenticationNotification.EVENT_TYPE_LOGOUT,
            { cancelable: false }
        ));
    }

    announceAuthetication(auth: AuthenticationResult) {
        this.subject
            .next(new CustomEvent(
                AuthenticationNotification.EVENT_TYPE_AUTHENTICATED,
                { cancelable: false, detail: auth }
            ));
    }

    announceLogout() {
        this.subject
            .next(new CustomEvent(
                AuthenticationNotification.EVENT_TYPE_LOGOUT,
                { cancelable: false }
            ));
    }

    get notification(): Observable<CustomEvent> {
        return this.subject.asObservable();
    }
}
