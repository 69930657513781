import { Injectable, InjectionToken, Optional, Inject } from '@angular/core';

export interface GlobalNavButtonDef {
  icon: string;
  tooltip?: string;
  action?: () => void;
  routerLink?: string;
  order?: number;
}

export const GLOBAL_NAV_BUTTONS = new InjectionToken<GlobalNavButtonDef>('Global navigation buttons');

@Injectable()
export class GlobalNavButtonsService {

  private readonly _buttons: ReadonlyArray<GlobalNavButtonDef>;

  constructor(
    @Inject(GLOBAL_NAV_BUTTONS) @Optional() buttons: GlobalNavButtonDef[]
  ) {
    this._buttons = buttons.sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  get buttons(): ReadonlyArray<GlobalNavButtonDef> {
    return this._buttons;
  }
}
