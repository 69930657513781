import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class PiwigoConnectorService {

  constructor(
    private readonly auth: AuthService,
    private readonly http: HttpClient,
    private readonly snackBar: MatSnackBar
  ) { }

  get piwigoUrl() {
    return 'https://pictures.slm-2.de';
  }

  openPiwigo(target?: Window) {
    this.http
      .post(
        `${this.piwigoUrl}/ws.php`,
        new HttpParams()
          .set('username', this.auth.username)
          .set('password', this.auth.password || '')
          .set('method', 'pwg.session.login')
          .toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {
            format: 'json'
          }
        }
      )
      .subscribe(
        () => this.openPiwigoWindow(target),
        e => this.announceFailure(e)
      );
  }

  private openPiwigoWindow(target?: Window) {
    if (target) {
      target.location.assign(this.piwigoUrl);
    } else {
      window.open(this.piwigoUrl, '_blank');
    }
  }

  private announceFailure(e: any) {
    console.error('Failed to log in to Piwigo', e);
    this.snackBar.open('Failed to log in to Piwigo image gallery', undefined, { duration: 5000 });
  }
}
