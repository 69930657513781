import { SafeResourceUrl } from '@angular/platform-browser';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const DASHBOARD_PROVIDERS = new InjectionToken<DashboardProvider>('DASHBOARD_PROVIDERS');

export interface DashboardDescription {
    provider: DashboardProvider;
    id: string;
    label?: string;
}

export interface DashboardProvider {
    dashboards: Observable<DashboardDescription[]>;
    getLoadedDashboardUrl(name: string): Observable<SafeResourceUrl>;
}
