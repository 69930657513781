import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User, UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<User | null> {

    constructor(private readonly users: UserService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): User | null | Observable<User | null> | Promise<User | null> {
        const username = route.params.name;
        if (username === 'new') {
            return null;
        }

        return this.users.getUser(username);
    }

}
