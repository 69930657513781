import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionMask } from '../auth.service';

@Injectable()
export class UserService {

  constructor(private readonly http: HttpClient) { }

  getAllUsers() {
    return this.http.get<User[]>('/users');
  }

  getUser(username: string) {
    return this.http.get<User>(`/users/${username}`);
  }

  save(user: User, isNew: boolean) {
    return isNew
      ? this.http.post<User[]>('/users', user).pipe(map(users => users[0]))
      : this.http.put<User>(`/users/${user.username}`, user);
  }

  delete(username: string) {
    return this.http.delete<void>(`/users/${username}`);
  }
}

export interface User {
  username: string;
  mail: string;
  password?: string;
  permissions: PermissionMask;
}
