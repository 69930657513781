import { Spring } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'slm-spring',
  templateUrl: './spring.component.html',
  styleUrls: ['./spring.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SpringComponent implements StructureTypeComponentBase, OnInit {

  model: Spring;

  constructor() { }

  ngOnInit() {
  }

}
