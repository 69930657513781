import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LibraryViewComponent } from './library-view/library-view.component';
import { AuthenticatedGuard } from '../auth/authenticated-guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'library',
                component: LibraryViewComponent,
                canActivate: [AuthenticatedGuard],
                children: [
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class LibraryRoutingModule { }
