import { UserService, User } from '../user.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';
import { catchError, filter, mapTo, mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'slm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  users?: User[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly snack: MatSnackBar,
    private readonly userService: UserService
  ) { }

  ngOnInit() {
    this.userService
      .getAllUsers()
      .subscribe(users => this.users = users);
  }

  deleteUser(user: User) {
    this.dialog
      .open(
        ConfirmDialogComponent,
        { data: {
          title: 'Delete user?',
          message: `Do you really want to delete the user ${user.username}? This action cannot be undone!`,
          confirmLabel: 'Delete'
        } }
      )
      .afterClosed()
      .pipe(
        filter(Boolean),
        mergeMap(() => this.userService.delete(user.username)),
        mapTo(`The user ${user.username} has been successfully deleted`),
        catchError(e => {
          console.error('Failed to delete user', e);
          return `Failed to delete user ${user.username}!`;
        })
      )
      .subscribe({
        next: msg => this.snack.open(msg, undefined, { duration: 5000 })
      });
  }
}
