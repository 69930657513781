import { Injectable } from '@angular/core';
import { Diff, EditDiff, ArrayDiff, DeleteDiff, NewDiff } from '../../sriis.service';

@Injectable()
export class ChangesService {

  constructor() { }

  formatDiff(diff: Diff) {
    return formatOneDiff(diff);
  }

}

function formatOneDiff(diff: Diff) {
  return (diffFormatters[diff.kind as keyof typeof diffFormatters] as (diff: Diff) => string)(diff);
}

const diffFormatters = {
  E: function formatE(diff: EditDiff) {
    return `${formatPath(diff.path)} changed from ${JSON.stringify(diff.lhs)} to ${JSON.stringify(diff.rhs)}`;
  },

  A: function formatA(diff: ArrayDiff) {
    return `For ${formatPath(diff.path)} at position ${diff.index || 0}: ${formatOneDiff(diff.item)}`;
  },

  D: function formatD(diff: DeleteDiff) {
    const deletion = `Deleted value ${JSON.stringify(diff.lhs)}`;
    return diff.path && diff.path.length
      ? `${deletion} at ${formatPath(diff.path)}`
      : deletion;
  },

  N: function format(diff: NewDiff) {
    return `Added ${JSON.stringify(diff.rhs)}`;
  }
};

function formatPath(path: string[]) {
  return path.join('.');
}
