import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GlobalNavButtonComponent } from './global-nav-button/global-nav-button.component';
import { GlobalNavButtonsService } from './global-nav-buttons.service';
import { UppercaseDirective } from './uppercase.directive';
import { QuickOpenComponent } from './quick-open/quick-open.component';
import { FormsModule } from '@angular/forms';
import { AuthModule } from '../auth';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    RouterModule
  ],
  declarations: [
    ConfirmDialogComponent,
    GlobalNavButtonComponent,
    QuickOpenComponent,
    UppercaseDirective,
  ],
  providers: [
    GlobalNavButtonsService
  ],
  exports: [
    ConfirmDialogComponent,
    GlobalNavButtonComponent,
    QuickOpenComponent,
    UppercaseDirective
  ],
  entryComponents: [
	ConfirmDialogComponent
  ]
})
export class CoreModule { }
