import { Checkdam } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'slm-checkdam',
  templateUrl: './checkdam.component.html',
  styleUrls: ['./checkdam.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CheckdamComponent implements StructureTypeComponentBase, OnInit {

  model: Checkdam;

  constructor() { }

  ngOnInit() {
  }

}
