import { IrrigationStructure } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'slm-irrigationstructure',
  templateUrl: './irrigationstructure.component.html',
  styleUrls: ['./irrigationstructure.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class IrrigationstructureComponent implements StructureTypeComponentBase, OnInit {

  model: IrrigationStructure;

  constructor() { }

  ngOnInit() {
  }

}
