import { Injectable, Inject } from '@angular/core';
import { DASHBOARD_PROVIDERS, DashboardProvider, DashboardDescription } from './dashboard-providers/dashboard-provider';
import { empty as emptyObservable, merge, Observable } from 'rxjs';
import { share, scan } from 'rxjs/operators';

interface DashboardInfo {
  provider: DashboardProvider;
  name: string;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly dashboardMap: Map<string, DashboardInfo> = new Map();
  private readonly dashboardDescs: Observable<DashboardDescription[]>;

  constructor(
    @Inject(DASHBOARD_PROVIDERS) providers: DashboardProvider[]
  ) {
    const sources = providers.reduce((acc, provider) => [...acc, provider.dashboards], new Array<Observable<DashboardDescription[]>>());
    this.dashboardDescs = merge(...sources)
      .pipe(
        scan(
          (acc, dashboards) => [...acc, ...dashboards],
          new Array<DashboardDescription>()
        ),
        share()
      );
    this.dashboardDescs
      .subscribe(dashboards => {
        this.dashboardMap.clear();
        dashboards.forEach(dashboard => {
          const name = dashboard.id;
          const label = dashboard.label || name;
          this.dashboardMap.set(name, { provider: dashboard.provider, name, label });
        });
      });
  }

  get dashboards(): Observable<DashboardDescription[]> {
    return this.dashboardDescs;
  }

  getLoadedDashboardUrl(name: string) {
    const info = this.dashboardMap.get(name);
    if (!info) {
      return emptyObservable();
    }

    return info.provider.getLoadedDashboardUrl(name);
  }
}
