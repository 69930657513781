import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SriisService, Changes, Diff } from '../../sriis.service';
import { ChangesService } from './changes.service';

export interface ViewChangesDialogData {
  sid: number;
}

@Component({
  selector: 'slm-view-changes-dialog',
  templateUrl: './view-changes-dialog.component.html',
  styleUrls: ['./view-changes-dialog.component.css']
})
export class ViewChangesDialogComponent {

  changes: Changes[];

  constructor(
    private readonly changesService: ChangesService,
    @Inject(MAT_DIALOG_DATA) data: ViewChangesDialogData,
    private readonly dialogRef: MatDialogRef<ViewChangesDialogComponent>,
    sriis: SriisService
  ) {
    sriis
      .getChanges(data.sid)
      .subscribe(changes => this.changes = changes);
  }

  formatTimestamp(input: Date | string) {
    const timestamp = typeof input === 'string'
      ? new Date(input)
      : input;

    return `${timestamp.toLocaleDateString()} at ${timestamp.toLocaleTimeString()}`;
  }

  formatDiff(diff: Diff) {
    return this.changesService.formatDiff(diff);
  }

  close() {
    this.dialogRef.close();
  }
}
