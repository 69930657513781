import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AuthModule } from '../auth';
import { CoreModule } from '../core/core.module';
import { BaseDataService } from './base-data.service';
import { PiwigoConnectorService } from './piwigo-connector.service';
import { SriisStructurePopup, sriisStructurePopupToken } from './sriis-map/sriis-structure-popup';
import { SriisRoutingModule } from './sriis-routing.module';
import { SriisMapService } from './sriis-map/sriis-map.service';
import { SriisService } from './sriis.service';
import { SriisComponent } from './sriis/sriis.component';
import { SriisExportComponent } from './sriis-export/sriis-export.component';
import { SriisMapComponent } from './sriis-map/sriis-map.component';
import { SriisStructureComponent } from './sriis-structure/sriis-structure.component';
import { StructureLocationComponent } from './sriis-structure/structure-location/structure-location.component';
import { BenchterraceComponent } from './sriis-structure/types/benchterrace/benchterrace.component';
import { CheckdamComponent } from './sriis-structure/types/checkdam/checkdam.component';
import { FordComponent } from './sriis-structure/types/ford/ford.component';
import { HanddugwellComponent } from './sriis-structure/types/handdugwell/handdugwell.component';
import { IrrigationstructureComponent } from './sriis-structure/types/irrigationstructure/irrigationstructure.component';
import { PipeculvertComponent } from './sriis-structure/types/pipeculvert/pipeculvert.component';
import { RoadComponent } from './sriis-structure/types/road/road.component';
import { SpringComponent } from './sriis-structure/types/spring/spring.component';
import { TreenurseryComponent } from './sriis-structure/types/treenursery/treenursery.component';
import { StructureSearchComponent } from './sriis-structure/structure-search/structure-search.component';
import { BaseDataSelectComponent } from './sriis-structure/base-data-select/base-data-select.component';
import { GalleryComponent } from './sriis-structure/gallery/gallery.component';
import { AttachmentsService } from './attachments.service';
import { AttachDialogComponent } from './sriis-structure/gallery/attach-dialog/attach-dialog.component';
import { sriisNavButtonProvider } from './sriis-nav-button';
import { piwigoNavButtonProvider } from './piwigo-nav-button';
import { ChangesService } from './sriis-structure/view-changes-dialog/changes.service';
import { ViewChangesDialogComponent } from './sriis-structure/view-changes-dialog/view-changes-dialog.component';
import { DashboardsComponent } from './dashboards/dashboards.component';
import { DashboardService } from './dashboards/dashboard.service';
import { DASHBOARD_PROVIDERS } from './dashboards/dashboard-providers/dashboard-provider';
import { SupersetDashboardProvider } from './dashboards/dashboard-providers/superset-dashboard-provider';
import { LAYERS, BaseLayerFactory, GeneralOverlaysFactory, AsyncLayerFactory } from './sriis-map/layers';
import { DacotaHttpInterceptor } from '../auth/dacota-http-interceptor';

@NgModule({
  imports: [
    AuthModule,
    BrowserAnimationsModule,
    ChartsModule,
    CommonModule,
    CoreModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    LeafletModule.forRoot(),
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    SriisRoutingModule
  ],
  declarations: [
    AttachDialogComponent,
    BaseDataSelectComponent,
    BenchterraceComponent,
    CheckdamComponent,
    DashboardsComponent,
    FordComponent,
    GalleryComponent,
    HanddugwellComponent,
    IrrigationstructureComponent,
    PipeculvertComponent,
    RoadComponent,
    SpringComponent,
    SriisComponent,
    SriisExportComponent,
    SriisMapComponent,
    SriisStructureComponent,
    StructureLocationComponent,
    StructureSearchComponent,
    TreenurseryComponent,
    ViewChangesDialogComponent
  ],
  entryComponents: [
    AttachDialogComponent,
    BenchterraceComponent,
    CheckdamComponent,
    FordComponent,
    HanddugwellComponent,
    IrrigationstructureComponent,
    PipeculvertComponent,
    RoadComponent,
    SpringComponent,
    TreenurseryComponent,
    ViewChangesDialogComponent
  ],
  providers: [
    AttachmentsService,
    BaseDataService,
    ChangesService,
    DashboardService,
    PiwigoConnectorService,
    SriisMapService,
    SriisService,
    {
      provide: sriisStructurePopupToken,
      useClass: SriisStructurePopup,
    },
    {
      provide: DASHBOARD_PROVIDERS,
      useClass: SupersetDashboardProvider,
      multi: true
    },
    {
      provide: LAYERS,
      useClass: BaseLayerFactory,
      multi: true
    },
    {
      provide: LAYERS,
      useClass: GeneralOverlaysFactory,
      multi: true
    },
    {
      provide: LAYERS,
      useClass: AsyncLayerFactory,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DacotaHttpInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    sriisNavButtonProvider,
    piwigoNavButtonProvider,
  ]
})
export class SriisModule { }
