import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { LibraryService, Document } from '../library.service';
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { flatMap, scan, map } from 'rxjs/operators';
import { DownloadService } from '../../core';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DocsConfig {
  filter?: Record<string, string>;
  sort?: Sort;
  range?: ListRange;
}

function updateDocsConfig(cfg: DocsConfig, input: any) {
  switch (input.type) {
    case 'force':
      break;

    case 'filters':
      cfg.filter = input.filters;
      break;

    case 'sort':
      cfg.sort = input.sort;
      break;

    default:
      cfg.range = input;
  }

  return cfg;
}

class DocumentDataSource extends DataSource<Document> {

  private readonly filterViewer = new Subject<{ type: 'filters', filters: Record<string, string> }>();
  private readonly forceSubject = new Subject<{ type: 'force' }>();
  private readonly sortProxy = new Subject();
  private sortSubscription?: Subscription;

  constructor(private readonly libService: LibraryService) {
    super();
  }

  set filter(filters: Record<string, string>) {
    this.filterViewer.next({ type: 'filters', filters });
  }

  set sort(matSort: MatSort) {
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }

    if (!matSort) {
      return;
    }

    this.sortSubscription = matSort.sortChange
      .asObservable()
      .pipe(
        map(sort => ({ type: 'sort', sort })),
      )
      .subscribe(this.sortProxy);
  }

  connect(collectionViewer: CollectionViewer): Observable<Document[]> {
    return merge(collectionViewer.viewChange, this.filterViewer, this.sortProxy, this.forceSubject)
      .pipe(
        scan(updateDocsConfig, {}),
        flatMap(cfg => this.libService
          .getDocumentsFor(cfg.filter && cfg.filter.category || '*')
          .pipe(
            map(docs => {
              if (!cfg.sort || !cfg.sort!.active) {
                return docs;
              }

              return docs.sort((a, b) => {
                const va = (a as Record<string, any>)[cfg.sort!.active];
                const vb = (b as Record<string, any>)[cfg.sort!.active];
                const na = va && va.name ? va.name : va;
                const nb = vb && vb.name ? vb.name : vb;
                const factor = cfg.sort!.direction === 'desc' ? -1 : 1;

                return na === nb
                  ? 0
                  : na > nb
                  ? -1 * factor
                  : factor;
              });
            }),
            map(docs => {
              return cfg.range && cfg.range.start && cfg.range.end
                ? docs.slice(cfg.range.start, cfg.range.end)
                : docs;
            })
          )
        )
      );
  }

  disconnect(): void {
  }

  forceUpdate() {
    this.forceSubject.next({ type: 'force' });
  }
}

@Component({
  selector: 'slm-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnChanges {

  dataSource: DocumentDataSource;

  @Input() category: string;
  @Input() dataChangeNotifier?: Observable<void>;

  @Output() documentSelected = new EventEmitter<Partial<Document>>(true);
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly displayedColumns: readonly string[] = ['title', 'type', 'version', 'download'];

  constructor(
    private readonly downloadService: DownloadService,
    private readonly service: LibraryService,
    private readonly snack: MatSnackBar
  ) {
    this.dataSource = new DocumentDataSource(this.service);
  }

  ngOnInit() {
    if (this.dataChangeNotifier) {
      this.dataChangeNotifier.subscribe(() => this.dataSource.forceUpdate());
    }

    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('category' in changes) {
      this.applyFilter(changes.category.currentValue);
    }
  }

  open(row: Document) {
    this.documentSelected.emit(row);
  }

  addDocument() {
    this.documentSelected.emit({ });
  }

  download(doc: Document, event: MouseEvent) {
    event.stopPropagation();

    this.service.getDocumentBlob(doc.id)
      .subscribe({
        next: blob => this.downloadService.download(blob, doc.fileName!),
        error: e => this.snack.open(`Download failed: ${e.message || e}`)
      });
  }

  private applyFilter(category: string) {
    this.dataSource.filter = { category };
  }
}
