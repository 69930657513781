import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DashboardDescription } from './dashboard-providers/dashboard-provider';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'slm-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {

  currentDashboardUrl: SafeResourceUrl;
  dashboards: Observable<DashboardDescription[]>;

  constructor(private readonly service: DashboardService) {
    this.dashboards = service.dashboards;
  }

  ngOnInit() {
    this.loadFirstDashboard();
  }

  private loadFirstDashboard() {
    this.dashboards!
      .pipe(
        filter(dashboards => !!dashboards.length),
        take(1)
      )
      .subscribe(
        dashboards => this.loadDashboard(dashboards[0].id)
      );
  }

  loadDashboard(name: string) {
    this.service
      .getLoadedDashboardUrl(name)
      .subscribe(url => this.currentDashboardUrl = url);
  }
}
