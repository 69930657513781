import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { concat } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(
    private readonly http: HttpClient
  ) { }

  getCategories() {
    return this.http.get<string[]>('/documents/categories');
  }

  getLanguages() {
    return this.http.get<DocumentLanguage[]>('/documents/languages');
  }

  getTypes() {
    return this.http.get<DocumentType[]>('/documents/types');
  }

  getVersions() {
    return this.http.get<DocumentVersion[]>('/documents/versions');
  }

  getDocumentsFor(category: string) {

    return this.http.get<Document[]>('/documents', { params: { category: category } });
  }

  save(doc: Partial<Document>, attachment?: File) {
    if (doc.id) {
      return this.saveExisting(doc, attachment);
    } else {
      return this.saveNew(doc, attachment);
    }
  }

  private saveExisting(doc: Partial<Document>, attachment?: File) {
    if (!doc.id) {
      throw new Error('Document ID missing');
    }

    const newDoc = {
      title: doc.title,
      author: doc.author,
      editor: doc.editor,
      notes: doc.notes,
      language: doc.language && doc.language.code,
      type: doc.type && doc.type.id,
      version: doc.version && doc.version.id,
      published: doc.published && (typeof doc.published === 'string' ? doc.published : moment(doc.published).format('YYY-MM-DD')),
      pages: doc.pages,
      restriction: doc.restriction,
      hardcopy: doc.hardcopy,
      compactDisk: doc.compactDisk,
      location: doc.location,
      categories: [...(doc.categories || [])]
    };

    const updatedDoc = this.http.patch<Document>(`/documents/${doc.id}`, newDoc);

    if (attachment) {
      return concat(
        updatedDoc,
        this.updateAttachment(doc.id!, attachment)
          // Only wait for upload to finish, do not emit result
          .pipe(filter(() => false))
      );
    }

    return updatedDoc;
  }

  private updateAttachment(id: string, attachment: File) {
    const form = new FormData();

    form.append('document', attachment, attachment.name);

    return this.http.put(`/documents/${id}/file`, form);
  }

  private saveNew(doc: Partial<Document>, attachment?: File) {
    const formData = new FormData();

    formData.append('title', doc.title!);
    // tslint:disable curly
    if (doc.author) formData.append('author', doc.author);
    if (doc.editor) formData.append('editor', doc.editor);
    if (doc.notes) formData.append('notes', doc.notes);
    if (doc.language && doc.language.code) formData.append('language', doc.language.code);
    if (doc.type && doc.type.id) formData.append('type', '' + doc.type.id);
    if (doc.version && doc.version.id) formData.append('version', '' + doc.version.id);
    if (doc.published) formData.append('published', typeof doc.published === 'string' ? doc.published : doc.published.toISOString());
    if (doc.pages) formData.append('pages', '' + doc.pages);
    if (doc.restriction) formData.append('restriction', doc.restriction);
    if (typeof doc.hardcopy === 'boolean') formData.append('hardcopy', '' + doc.hardcopy);
    if (typeof doc.compactDisk === 'boolean') formData.append('compactDisk', '' + doc.compactDisk);
    if (doc.location) formData.append('location', doc.location);
    if (doc.categories) doc.categories.forEach(cat => formData.append('categories', cat));
    // tslint:enable curly

    if (attachment) {
      formData.append('mime', attachment.type);
      formData.append('document', attachment, attachment.name);
    }

    return this.http.post<Document>('/documents', formData);
  }

  getDocumentBlob(id: string) {
    return this.http.get(`/documents/${id}/file`, { responseType: 'blob' });
  }
}

export interface DocumentLanguage {
  code: string;
  name: string;
}

export interface DocumentType {
  id: number;
  name: string;
}

export interface DocumentVersion {
  id: number;
  name: string;
}

export interface Document {
  id: string;
  title: string;
  author?: string;
  editor?: string;
  language?: DocumentLanguage;
  type?: DocumentType;
  version?: DocumentVersion;
  published?: string | Date;
  pages?: number;
  restriction?: string;
  hardcopy: boolean;
  compactDisk: boolean;
  location?: string;
  fileName?: string;
  mime?: string;
  notes?: string | null;
  uploaded?: string | Date;
  owner: string;
  categories: string[];
  subCategory?: string;
}
