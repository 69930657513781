import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthenticationNotification } from './authentication-notification';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { AuthorizeDirective } from './authorize.directive';
import { UserEditGuard } from './user-edit/user-edit.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DacotaHttpInterceptor } from './dacota-http-interceptor';
import { AuthenticatedGuard } from './authenticated-guard';

@NgModule({
  imports: [
    AuthRoutingModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  declarations: [LoginComponent, AuthorizeDirective],
  exports: [
    AuthorizeDirective,
    LoginComponent
  ],
  entryComponents: [LoginComponent],
  providers: [
    AuthenticatedGuard,
    AuthenticationNotification,
    AuthService,
    UserEditGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DacotaHttpInterceptor,
      multi: true
    }
  ]
})
export class AuthModule { }
