import { PipeCulvert } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'slm-pipeculvert',
  templateUrl: './pipeculvert.component.html',
  styleUrls: ['./pipeculvert.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PipeculvertComponent implements StructureTypeComponentBase, OnInit {

  model: PipeCulvert;

  constructor() { }

  ngOnInit() {
  }

}
