import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Document, LibraryService, DocumentType, DocumentLanguage, DocumentVersion } from '../library.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DownloadService } from '../../core';

@Component({
  selector: 'slm-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit {

  @Input() document: Partial<Document> = {};
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('categoryInput', { static: false }) categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('filePanel', { static: true }) filePanel: MatExpansionPanel;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

  typeObjects: DocumentType[] = [];
  types: string[] = [];

  $categories!: Observable<string[]>;
  languages: DocumentLanguage[] = [];
  versions: DocumentVersion[] = [];

  newFile?: File;

  saving = false;

  constructor(
    private readonly downloadService: DownloadService,
    private readonly library: LibraryService,
    private readonly snack: MatSnackBar
  ) { }

  ngOnInit() {
    this.library.getTypes().subscribe(types => {
      this.typeObjects = types;
      this.types = types.map(type => type.name);
    });

    this.$categories = this.library.getCategories();
    this.library.getLanguages().subscribe(languages => this.languages = languages);
    this.library.getVersions().subscribe(versions => this.versions = versions);

    this.conditionallyOpenFilePanel();
  }

  conditionallyOpenFilePanel() {
    if (this.document.fileName || !this.document.id) {
      this.filePanel.open();
    }
  }

  get documentType() {
    const typeObj = this.document.type;

    return typeObj && typeObj.name || '';
  }

  set documentType(value: string) {
    this.document.type = this.typeObjects.find(type => type.name === value);
  }

  get documentVersion(): number | null {
    return this.document.version && this.document.version.id || null;
  }

  set documentVersion(value: number | null) {
    this.document.version = this.versions.find(version => version.id === +(value || 0));
  }

  get documentLanguage() {
    return this.document.language && this.document.language.code || null;
  }

  set documentLanguage(value: string | null) {
    this.document.language = this.languages.find(language => language.code === value);
  }

  addCategory(event: any) {
    if (!event.value) {
      return;
    }

    this.document.categories = [...(this.document.categories || []), event.value];
    this.categoryInput.nativeElement.value = '';
  }

  removeCategory(category: string) {
    const categories = this.document.categories;
    if (!categories) {
      return;
    }

    const index = categories.indexOf(category);
    if (index < 0) {
      return;
    }

    categories.splice(index, 1);
    this.document.categories = [...categories];
  }

  categoryCompletionSelected(event: MatAutocompleteSelectedEvent) {
    this.addCategory(event.option);
  }

  fileSelected() {
    this.newFile = this.fileInput.nativeElement.files && this.fileInput.nativeElement.files[0] || undefined;
  }

  download() {
    const id = this.document.id;
    if (!id) {
      return;
    }

    this.library.getDocumentBlob(id)
      .subscribe({
        next: blob => this.downloadService.download(blob, this.document.fileName || 'file_name_unknown'),
        error: e => this.snack.open(`Download failed due to: ${e.message || e}`)
      });
  }

  close() {
    this.closeEvent.emit(false);
  }

  save() {
    this.saving = true;

    this.library
      .save(this.document, this.newFile)
      .subscribe({
        next: newDoc => {
          this.document = newDoc;
          this.snack.open('Document saved successfully', undefined, { duration: 3000 });
          this.saving = false;
          this.closeEvent.emit(true);
        },
        error: error => {
          this.snack.open(`Failed to save document due to: ${error.message || error}`, undefined, { duration: 5000 });
          this.saving = false;
        }
      });
  }
}
