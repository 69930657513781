import { SriisStructurePopup, sriisStructurePopupToken } from './sriis-structure-popup';
import { Location, LocationStrategy } from '@angular/common';
import { SriisService } from '../sriis.service';
import { Inject, Injectable } from '@angular/core';
import * as leaflet from 'leaflet';
import { mapValues } from 'lodash/fp';
import { map } from 'rxjs/operators';
import { Structure } from '../sriis-types';
import { Observable } from 'rxjs';

@Injectable()
export class SriisMapService {

  constructor(
    location: LocationStrategy,
    private readonly sriis: SriisService,
    @Inject(sriisStructurePopupToken) private readonly popup: SriisStructurePopup
  ) {
    const base = location.getBaseHref();
     defaultMarker = leaflet.icon({
      iconUrl: Location.joinWithSlash(base, 'assets/marker-icon.png'),
      shadowUrl: Location.joinWithSlash(base, 'assets/marker-shadow.png'),
      iconAnchor: [12, 40]
    });
  }

  getStructureLayers(): Observable<leaflet.LayerGroup> {
    return this.sriis
      .getStucturesForMap()
      .pipe(
        map(mapValues((s: Structure[]) => this.putStructuresOnLayer(s)))
      );
  }

  putStructuresOnLayer(structs: Structure[]): leaflet.LayerGroup {
    return leaflet.layerGroup(structs.map(s => this.structureToMarker(s)));
  }

  private structureToMarker(s: Structure): leaflet.Marker {
    const marker = leaflet
      .marker(leaflet.latLng([+s.latitude, +s.longitude]), { icon: defaultMarker })
      .bindPopup(this.popup);

    marker.on('popupopen', () => this.popup.setStructure(s));

    return marker;
  }

}

let defaultMarker: leaflet.Icon;
