import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'slm-quick-open',
  templateUrl: './quick-open.component.html',
  styleUrls: ['./quick-open.component.css']
})
export class QuickOpenComponent implements OnInit {
  private readonly structureIdMatcher = /^(?:AM|OR|TI)-[A-Z]{2}-(?:HD|SP|FO|BT|RO|PC|TN|IS|CD)-\d{3}$/;
  readonly structureIdPattern = this.structureIdMatcher.source;

  quickOpenId: string | undefined;

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  openQuick(id: string) {
    if (!this.structureIdMatcher.exec(id)) {
      return;
    }

    this.quickOpenId = undefined;
    this.router.navigate(['sriis', 'structure', id]);
  }
}
