import { Component, forwardRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BaseDataService, BaseDataType } from '../../base-data.service';
import { BaseDataBase } from '../../sriis-types';

@Component({
  selector: 'slm-base-data-select',
  templateUrl: './base-data-select.component.html',
  styleUrls: ['./base-data-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseDataSelectComponent),
      multi: true
    }
  ]
})
export class BaseDataSelectComponent implements ControlValueAccessor, OnInit {

  @Input()
  type: BaseDataType | keyof typeof BaseDataType;

  @Input()
  placeholder?: string;

  @Input()
  required?: boolean;

  @Input()
  multiple?: boolean;

  @Input()
  filter?: { [key: string]: any };

  @Input()
  orderby?: 'name' | 'id';

  @ViewChild('selectCtrl', { static: true }) select: MatSelect;

  @HostBinding('style.display')
  _display: string | undefined = 'none';

  values: BaseDataBase[];

  private _value: number | number[];

  private _propagateChange: (_: any) => void = () => {};

  constructor(
    private readonly baseData: BaseDataService
  ) {
  }

  ngOnInit() {
    const normalizedType = typeof this.type === 'string' ? BaseDataType[this.type] : this.type;

    this.baseData
      .getBaseData(normalizedType)
      .subscribe(
        data => {
          const values = this.filterData(data);
          if (this.orderby === 'name') {
            values.sort((a, b) => {
              const aName = a.name.toLowerCase();
              const bName = b.name.toLowerCase();

              return aName === bName ? 1 : aName > bName ? 1 : -1;
            });
          }

          this.values = values;
          this._display = this.values.length ? undefined : 'none';
        },
        e => {
          this.values = [{ id: 0, name: 'ERROR!' }];
          console.error(e);
        }
      );
  }

  private filterData(data: BaseDataBase[]): BaseDataBase[] {
    return this.filter
      ? data.filter(item => this.filterItem(item))
      : data;
  }

  private filterItem(item: BaseDataBase): boolean {
    if (!this.filter) {
      return true;
    }

    return Object
      .keys(this.filter)
      .reduce(
        (result, key) => {
          if (!result) {
            return false;
          }

          const fieldValue = item[key as keyof BaseDataBase];
          const fieldFilter = this.filter![key];
          if (typeof fieldFilter === 'function') {
            return fieldFilter(fieldValue);
          }

          if (fieldFilter instanceof RegExp) {
            const testValue = typeof fieldValue === 'string'
              ? fieldValue
              : fieldValue
              ? fieldValue.toString()
              : null;

            return (testValue !== null) && fieldFilter.test(testValue);
          }

          return fieldValue === fieldFilter;
        },
        true
      );
  }

  writeValue(obj: number | number[]): void {
    this._value = obj;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.select.setDisabledState(isDisabled);
  }

  get value() {
    return this._value;
  }

  set value(v: number | number[]) {
    this._value = v;
    this._propagateChange(v);
  }

  getItemId(index: number, item: BaseDataBase) {
    return item.id;
  }
}
