import { GlobalNavButtonDef, GLOBAL_NAV_BUTTONS } from '../core/global-nav-buttons.service';
import { ValueProvider } from '@angular/core';

export const libraryNavButtonProvider: ValueProvider = {
    provide: GLOBAL_NAV_BUTTONS,
    useValue: {
        icon: 'local_library',
        routerLink: '/library',
        tooltip: 'Access documents',
        order: 20
    } as GlobalNavButtonDef,
    multi: true
};
