import * as moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Structure, StructureBaseType } from './sriis-types';

export interface MapObject<T> {
  [key: string]: T;
}


export enum ChangeSetOperation {
  None,
  Create,
  Delete,
  Modify
}

export interface EditDiff {
  kind: 'E';
  lhs: any;
  rhs: any;
  path: string[];
}

export interface NewDiff {
  kind: 'N';
  rhs: any;
}

export interface DeleteDiff {
  kind: 'D';
  path: string[];
  lhs: any;
}

export interface ArrayDiff {
  kind: 'A';
  path: string[];
  index?: number;
  item: Diff;
}

export type Diff = EditDiff | NewDiff | DeleteDiff | ArrayDiff;

export interface Changes {
  id: number;
  sid: number;
  op: ChangeSetOperation;
  changes: Diff[];
  timestamp: Date;
  user: string;
}

const FILTER_NAME_MAP: { [key: string]: string } = {
  activeShape: 'structure:activeShape.id',
  mws: 'structure:microWatershed.id',
  region: 'structure:microWatershed:watershed:woreda:region.id',
  scheme: 'structure:scheme.id',
  status: 'structure:status.id',
  woreda: 'structure:microWatershed:watershed:woreda.id'
};

@Injectable()
export class SriisService {

  constructor(private readonly http: HttpClient) { }

  getStructure<T extends StructureBaseType>(sid: number): Observable<T> {
    return this.http
      .get(`/sriis/structure/${sid}`) as Observable<T>;
  }

  getStructures(filter?: any, sort?: any): Observable<StructureBaseType[]> {
    const params = filter ? this.mapFilterToParams(filter) : undefined;

    return this.http
      .get<StructureBaseType[]>('/sriis/export/all/json', { params });
  }

  exportAsBlob(format: string, filter: any, commonOnly?: boolean) {

    const params = this.mapFilterToParams(filter);

    return this.http
      .get(`/sriis/export/${commonOnly ? 'common' : 'all'}/${format}`, { responseType: 'blob', params });
  }

  exportStructureSelectList() {
    return this.http.get('/sriis/structures/select-list', { responseType: 'blob' });
  }

  private mapFilterToParams(filter: any): HttpParams {
    return Object
      .keys(filter)
      .reduce(
        (params: HttpParams, name: string) => {
          const value = filter[name];
          if (value === undefined) {
            return params;
          }

          const sriisFilterName = FILTER_NAME_MAP[name] || name;
          const operator = Array.isArray(value) ? ' in ' : '=';
          return params.append('filter', `${sriisFilterName}${operator}${JSON.stringify(value)}`);
        },
        new HttpParams()
      );
  }

  getStucturesForMap() {
    return this.http
      .get<MapObject<Structure[]>>('/sriis/map/markers');
  }

  updateStructure(structure: StructureBaseType) {
    return this.http
      .put<StructureBaseType>(`/sriis/structure/${structure.sid}`, this.sanitizeDates(structure));
  }

  private sanitizeDates(structure: StructureBaseType) {
    this.sanitizeDate(structure.structure, 'startDate');
    this.sanitizeDate(structure.structure, 'completedDate');
    this.sanitizeDate(structure.structure, 'handoverDate');
    this.sanitizeTimestamp(structure.structure, 'lastInspectionDate');

    return structure;
  }

  private sanitizeDate(
    structure: Structure,
    key: 'startDate' | 'completedDate' | 'handoverDate' | 'lastInspectionDate' | 'maintenanceDate'
  ) {
    const date = structure[key];
    if (date instanceof Date) {
      const mDate = moment(date);
      structure[key] = mDate.format('YYYY-MM-DD');
    }
  }

  private sanitizeTimestamp(
    structure: Structure,
    key: 'startDate' | 'completedDate' | 'handoverDate' | 'lastInspectionDate' | 'maintenanceDate'
  ) {
    const timestamp = structure[key];
    if (timestamp instanceof Date) {
      const mTimestamp = moment(timestamp);
      structure[key] = mTimestamp.format('YYYY-MM-DD[T]HH:mm:ss');
    }
  }

  getChanges(id: number | string) {
    return this.http.get<Changes[]>(`/sriis/structure/${id}/changes`);
  }
}
