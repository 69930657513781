import { Injectable, InjectionToken, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as leaflet from 'leaflet';
import { Observer, Observable, Subscription } from 'rxjs';
import { Structure } from '../sriis-types';

export const sriisStructurePopupToken = new InjectionToken<SriisStructurePopup>('sriisStructurePopup');

@Injectable()
export class SriisStructurePopup extends leaflet.Popup {

    private linkSubscription: Subscription | undefined;

    constructor(
        private readonly router: Router,
        private readonly zone: NgZone
    ) {
        super();

        this.on('remove', () => this.linkSubscription && (this.linkSubscription = void this.linkSubscription.unsubscribe()));
    }

    setStructure(struct: Structure) {
        this.setContent(this.getPopupContent(struct));
    }

    private getPopupContent(s: Structure) {
        const root = document.createElement('div');
        appendHeading(root, `${s.structureName || ''} (${s.code})`);

        const info = document.createElement('dl');
        info.className = 'popup-info';
        appendInfo(info, 'Scheme', this.getName(s.scheme));
        appendInfo(info, 'Status', this.getName(s.status));
        appendInfo(info, 'Woreda', this.getName(s.microWatershed && s.microWatershed.watershed && s.microWatershed.watershed.woreda));
        appendInfo(info, 'Watershed', this.getName(s.microWatershed && s.microWatershed.watershed));
        appendInfo(info, 'MWS', this.getName(s.microWatershed));

        root.appendChild(info);

        const link = this.appendLink(root, s.sid);
        this.linkSubscription = link.subscribe(sid => this.router.navigate(['sriis', 'structure', sid]));

        return root;
    }

    private getName(obj: { name: string } | undefined) {
        return obj && obj.name || 'unknown';
    }
    appendLink(root: HTMLElement, sid: number): Observable<number> {
        return Observable.create((observer: Observer<number>) => {
            const link = document.createElement('a');
            link.href = '#';
            const listener = (e: MouseEvent) => {
                e.preventDefault();
                link.removeEventListener('click', listener);
                this.zone.run(() => {
                    observer.next(sid);
                    observer.complete();
                });
            };
            link.addEventListener('click', listener);
            link.textContent = 'Show details';
            root.appendChild(link);
        });
    }
}

function appendHeading(root: HTMLElement, content: string) {
    const title = document.createElement('h4');
    title.className = 'mat-subheading-1';
    title.textContent = content;
    root.appendChild(title);
}

function appendInfo(dl: HTMLDListElement, title: string, content: string) {
    const infoTitle = document.createElement('dt');
    infoTitle.className = 'mat-body-2';
    infoTitle.textContent = title;
    dl.appendChild(infoTitle);
    const infoContent = document.createElement('dd');
    infoContent.className = 'mat-caption';
    infoContent.textContent = content;
    dl.appendChild(infoContent);
}
