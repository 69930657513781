import { Component, Input, OnInit } from '@angular/core';
import { GlobalNavButtonDef } from '../global-nav-buttons.service';

@Component({
  selector: 'slm-global-nav-button',
  templateUrl: './global-nav-button.component.html',
  styleUrls: ['./global-nav-button.component.css']
})
export class GlobalNavButtonComponent implements OnInit {

  @Input()
  button: GlobalNavButtonDef;

  constructor() { }

  ngOnInit() {
  }

  action() {
    if (this.button.action) {
      this.button.action();
    }
  }
}
