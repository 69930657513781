import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationNotification } from './authentication-notification';
import { AuthenticationResult } from './auth.service';

@Injectable()
export class DacotaHttpInterceptor implements HttpInterceptor {

    private jwt: string | undefined;

    constructor(authNotification: AuthenticationNotification) {
        authNotification.notification
            .subscribe(e => {
                switch (e.type) {
                case AuthenticationNotification.EVENT_TYPE_AUTHENTICATED:
                    this.jwt = (e.detail as AuthenticationResult).jwt;
                    break;

                case AuthenticationNotification.EVENT_TYPE_LOGOUT:
                    this.jwt = undefined;
                    break;
                }
            });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const sriisReq = this.jwt && isOriginSameAsLocation(req.url)
            ? req.clone({ setHeaders: { Authorization: `Bearer ${this.jwt}`}, withCredentials: true })
            : req;

        return next.handle(sriisReq);
    }
}

const URL_HOST_PATTERN = /^(\w+:)\/\/([\w.\-]+)?(?::(\d+))?\/?/;
const DEFAULT_PORTS = new Map([['http:', 80], ['https:', 443]]);
interface SiteOrigin {
    protocol: string;
    hostname: string;
    port: string;
}

function isOriginSameAsLocation(url: string): boolean {
    const pageLocation = window.location;
    const urlMatch = URL_HOST_PATTERN.exec(url) || [null, pageLocation.protocol, pageLocation.hostname, pageLocation.port];
    const urlparts: SiteOrigin = {
        protocol: urlMatch[1] || '',
        hostname: urlMatch[2] || '',
        port: urlMatch[3] || ''
    };

    function defaultPort(protocol: string) {
       return DEFAULT_PORTS.get(protocol) || 80;
    }

    function portOf(location: SiteOrigin) {
       return location.port || defaultPort(location.protocol || pageLocation.protocol);
    }

    return !!(
        (urlparts.protocol && (urlparts.protocol === pageLocation.protocol))
        && (urlparts.hostname && (urlparts.hostname === pageLocation.hostname))
        && (urlparts.hostname && (portOf(urlparts) === portOf(pageLocation)))
    );
}
