import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Inject } from '@angular/core';
import { latLng, MapOptions, Layer, LayerGroup, latLngBounds, markerClusterGroup, MarkerClusterGroup } from 'leaflet';
import { values } from 'lodash/fp';
import { Observable, Subscription } from 'rxjs';
import { LAYERS, LayerFactory, LayerType } from './layers';
import { SriisMapService } from './sriis-map.service';
import { Structure } from '../sriis-types';

@Component({
  selector: 'slm-sriis-map',
  templateUrl: './sriis-map.component.html',
  styleUrls: ['./sriis-map.component.css']
})
export class SriisMapComponent implements OnInit, OnChanges, OnDestroy {

  readonly DEFAULT_BASE_LAYER = 'Open Street Map';

  private _height = '600px';
  private _mapStyle: { [key: string]: string };

  @Input() set height(height: number) {
    this._height = height + 'px';
  }

  @Input() hilightLayerName?: string;
  @Input() hilightedStructures?: Structure[] | Structure;

  @Input() zoom?: number;

  private updateSubscription?: Subscription;
  @Input() set updateObservable(observable: Observable<boolean>) {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    this.updateSubscription = observable.subscribe(() => this.updateHilightLayer());
  }

  private hilightLayer: LayerGroup;
  private hilightLayerKey: string;

  readonly layers: Layer[] = [];

  readonly leafletOptions: MapOptions = {
    layers: this.layers,
    zoom: this.zoom || 6,
    center: latLng([9.124, 39.485]),
    maxBounds: latLngBounds(
      latLng(3.3, 32.9),
      latLng(15.0, 48.0)
    ),
  };

  readonly layersControl: { baseLayers: Record<string, Layer>, overlays: Record<string, Layer> } = {
    baseLayers: {},
    overlays: {}
  };

  constructor(
    private readonly sriisMaps: SriisMapService,
    @Inject(LAYERS) private readonly layerFactories: LayerFactory[]
  ) {
  }

  ngOnInit() {
    this.loadLayers();
    this.loadStructureLayers();
  }

  private loadLayers() {
    this.layerFactories
      .forEach(factory => {
        const layers = factory.build();
        const target = factory.type === LayerType.Base
          ? this.layersControl.baseLayers
          : this.layersControl.overlays;

        layers.forEach(layer => {
          if (isPromise(layer.layer)) {
            layer.layer
              .then(resolved => this.addLayer(layer.name, resolved, target, layer.enable));
          } else {
            this.addLayer(layer.name, layer.layer, target, layer.enable);
          }
        });
      });
  }

  private addLayer(name: string, layer: Layer, target: Record<string, Layer>, enable?: boolean) {
    target[name] = layer;
    if (enable) {
      this.layers.push(layer);
    }
  }

  private loadStructureLayers() {
    const clusterGroup = markerClusterGroup.layerSupport({singleAddRemoveBufferDuration: 100, removeOutsideVisibleBounds: true });
    this.layers.push(clusterGroup);

    this.sriisMaps
      .getStructureLayers()
      .subscribe(layers => this.addStructureLayers(layers, clusterGroup));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hilightLayerName || changes.hilightedStructures) {
      this.updateHilightLayer();
    }
  }

  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  private addStructureLayers(layers: LayerGroup, clusterGroup?: MarkerClusterGroup.LayerSupport) {
    Object.assign(this.layersControl.overlays, layers);

    if (clusterGroup) {
      clusterGroup.checkIn(values(layers));
    }

    if (!this.hilightedStructures) {
      this.layers.push(...values(layers));
    }
  }

  updateHilightLayer() {
    this.removeHilightLayer();
    this.addHilightLayer();
  }

  private removeHilightLayer() {
    if (!(this.hilightLayer && this.hilightLayerKey)) {
      return;
    }

    const layerIndex = this.layers.indexOf(this.hilightLayer);
    this.layers.splice(layerIndex, 1);
    delete this.layersControl.overlays[this.hilightLayerKey];
  }

  private addHilightLayer() {
    if (!this.hilightedStructures) {
      return;
    }

    this.hilightLayer = this.sriisMaps
      .putStructuresOnLayer(
        Array.isArray(this.hilightedStructures)
          ? this.hilightedStructures
          : [this.hilightedStructures]
      );

    this.hilightLayerKey =
      this.hilightLayerName
      || (
        Array.isArray(this.hilightedStructures)
          ? { code: 'Hilighted structures' }
          : this.hilightedStructures
      ).code;
    this.layersControl.overlays[this.hilightLayerKey] = this.hilightLayer;
    this.layers.push(this.hilightLayer);
  }

  get mapStyle() {
    if (!this._mapStyle || this._mapStyle.height !== this._height) {
      this._mapStyle = {
        height: this._height
      };
    }

    return this._mapStyle;
  }

}

function isPromise(candidate: unknown): candidate is Promise<unknown> {
  return !!candidate
    && typeof candidate === 'object'
    && typeof (candidate as Promise<unknown>).then === 'function';
}
