import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  download(data: Blob, fileName: string) {
    if (navigator.msSaveOrOpenBlob) {
      this.downloadIE(data, fileName);
    } else {
      this.downloadWithA(data, fileName);
    }
  }

  private downloadWithA(data: Blob, fileName: string) {
    const anchor = document.createElement('a');
    anchor.setAttribute('style', 'display: none');
    anchor.href = URL.createObjectURL(data);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();

    window.setTimeout(
      () => {
        document.body.removeChild(anchor);
        URL.revokeObjectURL(anchor.href);
      },
      0
    );
  }

  private downloadIE(data: Blob, fileName: string) {
    navigator.msSaveOrOpenBlob(data, fileName);
  }
}
