import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, flatMap, catchError, filter } from 'rxjs/operators';
import { AttachmentsService, NewAttachment } from '../../attachments.service';
import { Attachment } from '../types/attachment';
import { AttachDialogComponent } from './attach-dialog/attach-dialog.component';
import { DownloadService } from '../../../core';

@Component({
  selector: 'slm-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, OnDestroy {

  @Input()
  sid: number;
  attachments: Attachment[] = [];

  @Output()
  close = new EventEmitter<void>();

  private previewCache: { [key: string]: Observable<SafeUrl> } = {};

  constructor(
    private readonly attachmentService: AttachmentsService,
    private readonly domSanitizer: DomSanitizer,
    private readonly downloadService: DownloadService,
    private readonly matDialog: MatDialog,
    private readonly snack: MatSnackBar
  ) { }

  ngOnInit() {
    this.attachmentService
      .getAttachmentsFor(this.sid)
      .subscribe(attachments => this.attachments = attachments);
  }

  ngOnDestroy() {
    Object
      .keys(this.previewCache)
      .forEach(k => this.previewCache[k].subscribe(url => URL.revokeObjectURL(url as string)));
  }

  getPreviewUrl(attachment: string | Attachment) {
    const name = typeof attachment === 'string' ? attachment : attachment.name;
    if (this.previewCache[name]) {
      return this.previewCache[name];
    }

    return this.previewCache[name] = this.attachmentService
      .getPreview(this.sid, name)
      .pipe(
        map(preview => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(preview))),
        publishReplay(1),
        refCount()
      );
  }

  download(attachment: string | Attachment) {
    const name = typeof attachment === 'string' ? attachment : attachment.name;

    this.attachmentService
      .getBlob(this.sid, name)
      .subscribe(
        blob => this.downloadService.download(blob, name)
      );
  }

  attach() {
    this.matDialog
      .open<AttachDialogComponent, NewAttachment>(AttachDialogComponent)
      .afterClosed()
      .pipe(
        filter<NewAttachment>(Boolean),
        catchError(e => new Array<NewAttachment>()),
        flatMap<NewAttachment, Observable<Attachment>>(atmt => this.attachmentService.upload(this.sid, atmt))
      )
      .subscribe(
        atmt => this.appendAttachment(atmt),
        e => this.announceError(e)
      );
  }

  private appendAttachment(attachment: Attachment) {
    this.attachments.push(attachment);
  }

  private announceError(e: Error | HttpErrorResponse) {
    const message = e instanceof HttpErrorResponse ? e.statusText : e.message;
    this.snack.open(`Attaching file failed: ${message}`, undefined, { duration: 7500 });
    console.error(e);
  }
}
