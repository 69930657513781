import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationResult, AuthService } from '../auth.service';

@Injectable()
export class UserEditGuard implements CanActivate {

    constructor(
        private readonly auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.auth
            .ensureAuthenticated(state.url)
            .pipe(
                map<false | AuthenticationResult, boolean>(
                    v => !!(v && v.permissions && v.permissions.admin)
                )
            );
    }

}
