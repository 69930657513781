import { HandDugWell } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'slm-handdugwell',
  templateUrl: './handdugwell.component.html',
  styleUrls: ['./handdugwell.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class HanddugwellComponent implements StructureTypeComponentBase, OnInit {

  model: HandDugWell;

  constructor() { }

  ngOnInit() {
  }

}
