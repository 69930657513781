import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LibraryService } from '../library.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'slm-category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss']
})
export class CategorySelectionComponent implements OnInit {

  @Output() categorySelected = new EventEmitter<string>();
  $categories: Observable<string[]>;

  constructor(
    private readonly service: LibraryService
  ) { }

  ngOnInit() {
    this.$categories = this.service.getCategories();
  }

  selectCategory(category: string) {
    this.categorySelected.emit(category);
  }
}
