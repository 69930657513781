import { DataSource } from '@angular/cdk/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { curry } from 'lodash';
import { get, orderBy } from 'lodash/fp';
import * as moment from 'moment';
import { forkJoin, Observable, Observer, merge } from 'rxjs';
import { flatMap, map, tap, last } from 'rxjs/operators';
import { SriisService } from '../../sriis.service';
import { Region, Scheme, Status, StructureBaseType, MicroWatershed, Woreda, BaseDataBase, ActiveShape } from '../../sriis-types';
import { BaseDataService, BaseDataType } from '../../base-data.service';
import { DownloadService } from '../../../core';

class StructuresDataSource extends DataSource<StructureBaseType> {

  private currentSort: Sort;
  private currentFilter: Filter;

  constructor(private readonly sriis: SriisService, private readonly sort: Observable<Sort>, private readonly filter: Observable<Filter>) {
    super();
  }

  connect(): Observable<StructureBaseType[]> {
    return merge(
      this.sort.pipe(tap((sort: Sort) => this.currentSort = sort)),
      this.filter.pipe(tap((filter: Filter) => this.currentFilter = filter))
    )
    .pipe(
      flatMap(() => this.getSortedData())
    );
  }

  disconnect() { }

  getSortedData() {
    return this.sriis
      .getStructures(this.currentFilter)
      .pipe(
        map((s: StructureBaseType[]) => sortStructures(s, this.currentSort))
      );
  }
}

@Component({
  selector: 'slm-structure-search',
  templateUrl: './structure-search.component.html',
  styleUrls: ['./structure-search.component.css']
})
export class StructureSearchComponent implements OnInit {

  readonly displayedColumns = ['code', 'scheme', 'status', 'activeShape', 'region', 'woreda', 'mws', 'inspectionDate'];

  dataSource: StructuresDataSource;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  filterObserver: Observer<Filter>;
  filterObservable: Observable<Filter> = new Observable((o: Observer<Filter>) => void (this.filterObserver = o));

  activeShapes: ActiveShape[];
  mwses: MicroWatershed[];
  regions: Region[];
  schemes: Scheme[];
  statuses: Status[];
  woredas: Woreda[];

  criteria: Filter = {
    activeShape: [],
    mws: [],
    woreda: [],
    region: [],
    status: [],
    scheme: []
  };

  dataInteractivityDisplay: string | null = 'none';

  constructor(
    private readonly baseData: BaseDataService,
    private readonly downloadService: DownloadService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sriis: SriisService
  ) { }

  ngOnInit() {
    this.dataSource = new StructuresDataSource(this.sriis, this.sort.sortChange.asObservable(), this.filterObservable);
    this.loadBaseData()
      .pipe(
        flatMap(() => this.route.queryParams)
      )
      .subscribe(params => this.assignQueryParams(params));
  }

  private loadBaseData() {
    return forkJoin([
      this.baseData.getRegions().pipe(tap(regions => this.regions = orderBy(['name'], ['asc'], regions))),
      this.baseData.getBaseData<Woreda>(BaseDataType.woreda).pipe(tap(woredas => this.woredas = orderBy(['name'], ['asc'], woredas))),
      this.baseData.getSchemes().pipe(tap(schemes => this.schemes = orderBy(['name'], ['asc'], schemes))),
      this.baseData.getStatuses().pipe(tap(statuses => this.statuses = statuses)),
      this.baseData.getActiveShapes().pipe(tap(shapes => this.activeShapes = shapes)),
      this.baseData
        .getBaseData<MicroWatershed>(BaseDataType.microWatershed)
        .pipe(tap(mwses => this.mwses = orderBy(['name'], ['asc'], mwses)))
    ])
      .pipe(last());
  }

  private assignQueryParams(params: { [P in keyof Filter]: any }) {
    const assignableQueries = (Object.keys(params) as (keyof Filter)[])
      .filter(k => this.criteria.hasOwnProperty(k));

    assignableQueries.forEach(k => this.criteria[k] = (Array.isArray(params[k]) ? params[k] : [params[k]]).map(Number));
    if (assignableQueries.length) {
      this.search();
    }
  }

  itemId(index: number, item: BaseDataBase) {
    return item.id;
  }

  search() {
    this.filterObserver.next(this.removeEmptyCriteria(this.criteria));
    this.dataInteractivityDisplay = null;
  }

  private removeEmptyCriteria(criteria: Filter): Filter {
    return Object
      .keys(criteria)
      .reduce<Filter>(
        (res, key) => {
          const filterKey: keyof Filter = key as keyof Filter;
          const criterion = criteria[filterKey];
          if (criterion && criterion.length) {
            res[filterKey] = criterion;
          }

          return res;
        },
        {}
      );
  }

  open(structure: StructureBaseType) {
    this.router.navigate(['..', structure.sid], { relativeTo: this.route });
  }

  download() {
    this.dataSource
      .getSortedData()
      .subscribe(data => this.prepareForDownload(data));
  }

  private prepareForDownload(data: StructureBaseType[]) {
    const columns = ['Code', 'Scheme', 'Status', 'Current Shape', 'Region', 'Woreda', 'Micro watershed', 'Last inspection'];

    function formatDate(date: Date | undefined) {
      return date
        ? moment(date).format('YYYY-MM-DD')
        : '';
    }

    const exportRows = data.map<string[]>(row => [
      row.code || 'SID: ' + row.sid,
      get('structure.scheme.name', row),
      get('structure.status.name', row),
      get('structure.activeShape.name', row) || '',
      get('structure.microWatershed.watershed.woreda.region.name', row),
      get('structure.microWatershed.watershed.woreda.name', row),
      get('structure.microWatershed.name', row),
      formatDate(get<Date>('structure.lastInspectionDate', row))
    ]);

    const tabbedRows = [ columns.join('\t'), ...exportRows.map(row => row.join('\t')) ];
    const tabbedFile = tabbedRows.join('\r\n');
    const blob = new Blob([tabbedFile], { type: 'text/tab-separated-values' });

    this.downloadService.download(blob, 'dacota_serach_resuls.tab');
  }
}

interface Filter {
  activeShape?: number[];
  mws?: number[];
  woreda?: number[];
  region?: number[];
  scheme?: number[];
  status?: number[];
}

const sortPaths: { [key: string]: string } = {
  code: 'code',
  scheme: 'structure.scheme.name',
  status: 'structure.status.name',
  activeShape: 'struture.activeShape.name',
  region: 'structure.microWatershed.watershed.woreda.region.name',
  woreda: 'structure.microWatershed.watershed.woreda.name',
  mws: 'structure.microWatershed.name',
  inspectionDate: 'structure.lastInspectionDate'
};

function sortStructures(s: StructureBaseType[], sort: Sort) {
  if (!sort || !sort.active) {
    return s;
  }

  const sortPath = sortPaths[sort.active];
  const getter = get(sortPath);
  const direction = (+(sort.direction === 'asc') * 2 - 1);
  const comparator = curry(genericComparator)(direction);

  return s.sort((a, b) => {
    const va = getter(a);
    const vb = getter(b);

    return comparator(va, vb);
  });
}

function genericComparator(direction: number, a: any, b: any): number {
  if (a === b) {
    return 0;
  } else {
    const bNullish = b === null || b === undefined;

    const result = +(bNullish || b < a) * 2 - 1;

    return result * direction;
  }
}
