import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NewAttachment } from '../../../attachments.service';

@Component({
  selector: 'slm-attach-dialog',
  templateUrl: './attach-dialog.component.html',
  styleUrls: ['./attach-dialog.component.css']
})
export class AttachDialogComponent implements OnInit {

  attachment: NewAttachment = {};

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  beginSelection() {
    this.fileInput.nativeElement.click();
  }

  updateFileData() {
    const fileInput: HTMLInputElement = this.fileInput.nativeElement;
    if (!(fileInput && fileInput.files)) {
      return console.error('Could not get files');
    }

    const file = fileInput.files[0];
    if (!file) {
      this.resetUpload();
    } else {
      this.loadUpload(file);
    }
  }

  private resetUpload() {
    this.attachment.data = undefined;
    this.attachment.name = undefined;
  }

  private loadUpload(file: File) {
    this.attachment.name = file.name;
    this.attachment.data = file;
  }
}
