import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionMask } from '../../auth.service';
import { User, UserService } from '../user.service';

@Component({
  selector: 'slm-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {

  user: User;
  pwRepeat: string;
  isNew: boolean;
  initialPermissions: PermissionMask;

  @ViewChild('userForm', { static: true }) userForm: NgForm;
  @ViewChild('permSelectList', { static: true }) permSelectList: MatSelectionList;

  readonly permissions = [
    {
      value: 'write',
      label: 'Change existing data'
    },
    {
      value: 'create',
      label: 'Create new data'
    },
    {
      value: 'delete',
      label: 'Delete existing data'
    },
    {
      value: 'admin',
      label: 'Administrator'
    }
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly users: UserService
  ) { }

  ngOnInit() {
    this.user = { username: '', mail: '', password: '', permissions: { write: false, create: false, delete: false, admin: false } };
    (this.route.data as Observable<{ user: User }>)
      .subscribe(({ user }) => {
        if (user) {
          this.isNew = false;
          this.initialPermissions = Object.assign<PermissionMask, PermissionMask>({}, user.permissions);
          this.user = Object.assign<User, Partial<User>>(this.user, user);
        } else {
          this.isNew = true;
          this.initialPermissions = {};
        }
      });

    if (this.permSelectList.selectedOptions.changed) {
      this.permSelectList.selectedOptions.changed
        .subscribe(chg => {
          const perms = this.user.permissions;

          (chg.removed || [])
            .map(opt => opt.value)
            .filter(x => !!x)
            .forEach(perm => {
              if (perms[perm]) {
                this.userForm.form.markAsDirty();
              }

              perms[perm] = false;
            });
          (chg.added || [])
            .map(opt => opt.value)
            .filter(x => !!x)
            .forEach(perm => {
              if (!perms[perm]) {
                this.userForm.form.markAsDirty();
              }

              perms[perm] = true;
          });
        });
      }
  }

  get canSave() {
    return this.userForm.dirty
      && this.userForm.valid
      && this.passwordsMatch;
  }

  private get passwordsMatch() {
    return this.user.password === this.pwRepeat || (!this.user.password && !this.pwRepeat);
  }

  get passwordAsPattern() {
    return (this.user.password || '').replace(/[\.\\\(\)\[\]\{\}\?\*\+\^\$]/g, '\\$&');
  }

  save() {
    this.users
      .save(this.user, this.isNew)
      .subscribe(
        u => {
          this.snackBar
            .open(
              `User “${u.username}” successfuly saved`,
              undefined,
              { duration: 5000 }
            );
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        e => this.snackBar
          .open(
            `Saving user failed due to: ${e.error || e.message || e}`,
            undefined,
            { duration: 5000 }
          )
      );
  }
}
