import { Component, OnInit } from '@angular/core';
import { Document } from '../library.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'slm-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss']
})
export class LibraryViewComponent implements OnInit {

  category = '';
  selectedDocument?: Document;

  readonly savedNotifier = new Subject<void>();

  constructor() { }

  ngOnInit() {
  }

  open(doc: Document) {
    this.selectedDocument = doc;
  }

  closeDetails(saved: boolean) {
    this.selectedDocument = undefined;

    if (saved) {
      this.savedNotifier.next();
    }
  }

  setCategoryFilter(category: string) {
    this.category = category === 'All' ? '' : category;
  }
}
