import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SriisComponent } from './sriis/sriis.component';
import { SriisExportComponent } from './sriis-export/sriis-export.component';
import { SriisMapComponent } from './sriis-map/sriis-map.component';
import { SriisStructureComponent } from './sriis-structure/sriis-structure.component';
import { StructureSearchComponent } from './sriis-structure/structure-search/structure-search.component';
import { SriisStructureModelResolver } from './sriis-structure-model.resolver';
import { DashboardsComponent } from './dashboards/dashboards.component';
import { AuthenticatedGuard } from '../auth/authenticated-guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'sriis',
                component: SriisComponent,
                canActivate: [AuthenticatedGuard],
                children: [
                    {
                        path: 'export',
                        component: SriisExportComponent
                    },
                    {
                        path: 'map',
                        component: SriisMapComponent
                    },
                    {
                        path: 'dashboards',
                        component: DashboardsComponent
                    },
                    {
                        path: 'structure/search',
                        component: StructureSearchComponent
                    },
                    {
                        path: 'structure/:sid',
                        component: SriisStructureComponent,
                        resolve: {
                            model: SriisStructureModelResolver
                        }
                    },
                    {
                        path: 'structure',
                        pathMatch: 'full',
                        redirectTo: 'structure/search'
                    },
                    {
                        path: '',
                        redirectTo: 'map',
                        pathMatch: 'full'
                    }
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [SriisStructureModelResolver]
})
export class SriisRoutingModule { }
