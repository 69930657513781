import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'slm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  loggingIn = false;

  @Input()
  redirect?: string;

  constructor(
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snack: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => this.redirect = params.redirect);
  }

  login() {
    this.loggingIn = true;

    this.auth
      .authenticate(this.username, this.password)
      .subscribe(
        () => {
          if (this.redirect) {
            this.router.navigateByUrl(this.redirect);
          }
          this.loggingIn = false;
        },
        e => {
          this.snack
            .open(
              `Login failed: ${e.error || e.message || e}`,
              undefined,
              { duration: 5000 }
            );
          this.loggingIn = false;
        }
      );
  }
}
