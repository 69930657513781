import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgComponentOutlet } from '@angular/common';
import { Component, ComponentRef, OnInit, Type, ViewChild, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { omit } from 'lodash/fp';
import { Observable } from 'rxjs';
import { BaseDataService } from '../base-data.service';
import { SriisService } from '../sriis.service';
import { Status, StructureBaseType } from '../sriis-types';
import { BenchterraceComponent } from './types/benchterrace/benchterrace.component';
import { CheckdamComponent } from './types/checkdam/checkdam.component';
import { FordComponent } from './types/ford/ford.component';
import { HanddugwellComponent } from './types/handdugwell/handdugwell.component';
import { IrrigationstructureComponent } from './types/irrigationstructure/irrigationstructure.component';
import { PipeculvertComponent } from './types/pipeculvert/pipeculvert.component';
import { RoadComponent } from './types/road/road.component';
import { SpringComponent } from './types/spring/spring.component';
import { StructureTypeComponentBase } from './types/structure-type-component-base';
import { TreenurseryComponent } from './types/treenursery/treenursery.component';
import { ViewChangesDialogComponent } from './view-changes-dialog/view-changes-dialog.component';

const detailsComponentTypes: {[key: string]: Type<StructureTypeComponentBase>} = {
  1101010103: BenchterraceComponent,
  11010401: CheckdamComponent,
  11020402: FordComponent,
  11029901: HanddugwellComponent,
  99999999: IrrigationstructureComponent,
  11020403: PipeculvertComponent,
  11020401: RoadComponent,
  11029902: SpringComponent,
  11010399: TreenurseryComponent
};

@Component({
  selector: 'slm-sriis-structure',
  templateUrl: './sriis-structure.component.html',
  styleUrls: ['./sriis-structure.component.css']
})
export class SriisStructureComponent implements OnInit {

  model: StructureBaseType;

  statuses: Status[];

  detailsComponentType: Type<StructureTypeComponentBase>;

  @ViewChild('drawer', { static: true })
  drawer: MatSidenav;

  drawerIsOpen = false;

  private _drawerMode = 'over';

  @ViewChild('structureForm', { static: true }) structureForm: NgForm;

  @ViewChild(NgComponentOutlet, { static: true }) set detailsOutlet(outlet: NgComponentOutlet | undefined) {
    if (!outlet) {
      return;
    }

    const origOnChanges = outlet.ngOnChanges;
    const that = this;

    // Patch the outlet's ngOnChanges method so that we get notified (albeit indirect) when the
    // dynamic component changes
    outlet.ngOnChanges = function() {
      origOnChanges.apply(outlet, [...arguments] as [SimpleChanges]);

      const ref = outlet['_componentRef'] as ComponentRef<StructureTypeComponentBase>;
      if (!ref) {
        return;
      }

      ref.instance.model = that.model;
    };
  }

  constructor(
    private readonly baseData: BaseDataService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly snack: MatSnackBar,
    private readonly sriis: SriisService
  ) { }

  ngOnInit() {
    (this.route.data as Observable<{ model: StructureBaseType }>)
      .subscribe(({ model }) => {
        this.model = model;
        this.detailsComponentType = detailsComponentTypes[`${model.structure.idScheme}`];
      });

    this.baseData
      .getStatuses()
      .subscribe(statuses => this.statuses = statuses);

    this.breakpointObserver.observe(
      [Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait],
    ).subscribe(state => {
      this._drawerMode = state.matches
        ? 'over'
        : 'side';
    });
  }

  get drawerMode() {
    return this._drawerMode;
  }

  save() {
    const payload: StructureBaseType = omit(['ford', 'culvert'], this.model);
    payload.structure = omit(
      [
        'constructedAsPerDesign',
        'kebele',
        'maintenance',
        'microWatershed',
        'procurementMethod',
        'scheme',
        'status',
        'type',
        'uaFunctioning',
        'use',
        'utilizedAsPlanned'
      ],
      payload.structure
    );
    payload.code = payload.structure.code;

    this.sriis
      .updateStructure(payload)
      .subscribe(
        () => {
          this.structureForm.form.markAsPristine();
          this.snack.open('Saved sucessfully', undefined, { duration: 5000 });
        },
        e => this.snack.open(`Failed to save due to: ${e.error || e.message || e}`, undefined, { duration: 5000 })
      );
  }

  showChanges() {
    this.dialog
      .open(ViewChangesDialogComponent, { data: { sid: this.model.sid } });
  }
}
