import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, AuthenticationResult } from './auth.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

    constructor(
        private readonly auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.auth
            .ensureAuthenticated(state.url)
            .pipe(
                map(
                    v => typeof v === 'boolean'
                        ? v
                        : this.authorize(route, v as AuthenticationResult)
                )
            );
    }

    private authorize(route: ActivatedRouteSnapshot, auth: AuthenticationResult): boolean {
        return this.auth.authorize(route.data.permissionMask, auth.permissions);
    }
}
