import { ControlContainer, NgForm } from '@angular/forms';
import { BaseDataService } from '../../base-data.service';
import { MicroWatershed, Region, StructureBaseType, Watershed, Woreda, BaseDataBase } from '../../sriis-types';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'slm-structure-location',
  templateUrl: './structure-location.component.html',
  styleUrls: ['./structure-location.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class StructureLocationComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  model: StructureBaseType;

  private _region?: Region;
  private _woreda?: Woreda;
  private _watershed?: Watershed;
  private _microWatershed?: MicroWatershed;

  private updateTrigger: Observer<boolean>;
  updateOutlet: Observable<boolean> = Observable.create((o: Observer<boolean>) => this.updateTrigger = o);

  locations: Region[];

  constructor(private readonly baseData: BaseDataService) { }

  ngOnInit() {
    this.baseData
      .getLocationTree()
      .subscribe(tree => {
        this.locations = sortLocationTree(tree);
        this.initLocation();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.initLocation(changes.model.currentValue);
    }
  }

  ngOnDestroy() {
    this.updateTrigger.complete();
  }

  private initLocation(model: StructureBaseType = this.model) {
    if (!model || !this.locations) {
      return;
    }

    const mws = model.structure.microWatershed;
    const watershed = mws && mws.watershed;
    const woreda = watershed && watershed.woreda;
    const region = woreda && woreda.region;
    if ((this.region = region && this.locations.find(r => r.id === region.id))) {
      if ((this.woreda = woreda && this.region.woredas!.find(w => w.id === woreda.id))) {
        if ((this.watershed = watershed && this.woreda.watersheds!.find(w => w.id === watershed.id))) {
          this.microWatershed = mws && this.watershed.microWatersheds!.find(m => m.id === mws.id);
        }
      }
    }
  }

  get region(): Region | undefined {
    return this._region;
  }

  get woreda(): Woreda | undefined {
    return this._woreda;
  }

  get watershed(): Watershed | undefined {
    return this._watershed;
  }

  get microWatershed(): MicroWatershed | undefined {
    return this._microWatershed;
  }

  set region(region: Region | undefined) {
    this._region = region;

    const struct = this.model.structure;

    if (region
      && struct.microWatershed
      && struct.microWatershed.watershed
      && struct.microWatershed.watershed.woreda
      && struct.microWatershed.watershed.woreda.idRegion !== region.id
    ) {
      struct.idMicroWatershed = 0;
      struct.microWatershed = undefined;

      this._woreda = this._watershed = this._microWatershed = undefined;
    }

    struct.microWatershed =
      struct.microWatershed
      || { id: 0, idWatershed: 0, name: '' };
    struct.microWatershed.watershed =
      struct.microWatershed.watershed
      || { id: 0, idWoreda: 0, name: '' };
    struct.microWatershed.watershed.woreda =
      struct.microWatershed.watershed.woreda
      || { id: 0, idRegion: region && region.id || 0, name: '' };

    struct.microWatershed.watershed.woreda.region = { id: region && region.id || 0, name: region && region.name || '' };
  }

  set woreda(woreda: Woreda | undefined) {
    this.region = woreda && woreda.region;
    this._woreda = woreda;

    const struct = this.model.structure;

    if (woreda
      && struct.microWatershed
      && struct.microWatershed.watershed
      && struct.microWatershed.watershed.idWoreda !== woreda.id
    ) {
      struct.idMicroWatershed = 0;
      struct.microWatershed = {
        id: 0,
        name: '',
        idWatershed: 0,
        watershed: {
          id: 0,
          name: '',
          idWoreda: woreda.id,
          woreda: {
            id: woreda.id,
            name: woreda.name,
            idRegion: woreda.idRegion,
            region: woreda.region
          }
        }
      };

      this._watershed = this._microWatershed = undefined;
    }
  }

  set watershed(watershed: Watershed | undefined) {
    this.woreda = watershed && watershed.woreda;
    this._watershed = watershed;

    const struct = this.model.structure;

    if (watershed
      && struct.microWatershed
      && struct.microWatershed.idWatershed !== watershed.id
    ) {
      struct.idMicroWatershed = 0;
      struct.microWatershed = {
        id: 0,
        name: '',
        idWatershed: watershed.id,
        watershed: {
          id: watershed.id,
          name: watershed.name,
          idWoreda: watershed.idWoreda,
          woreda: watershed.woreda
        }
      };

      this._microWatershed = undefined;
    }
  }

  set microWatershed(mws: MicroWatershed | undefined) {
    this.watershed = mws && mws.watershed;
    this._microWatershed = mws;

    const struct = this.model.structure;

    if (mws) {
      struct.idMicroWatershed = mws.id;
      struct.microWatershed = {
        id: mws.id,
        name: mws.name,
        idWatershed: mws.idWatershed,
        watershed: mws.watershed
      };
    }
  }

  get latitude() {
    return this.model.structure.latitude;
  }

  get longitude() {
    return this.model.structure.longitude;
  }

  set latitude(lat: number) {
    this.model.structure.latitude = lat;
    this.updateTrigger.next(true);
  }

  set longitude(lon: number) {
    this.model.structure.longitude = lon;
    this.updateTrigger.next(true);
  }

  getItemId(index: number, item: BaseDataBase): number {
    return item.id;
  }
}

function sortLocationTree(tree: Region[]): Region[] {
  tree.sort(compareByName);

  tree.forEach(r => {
    if (!r.woredas) {
      return;
    }

    r.woredas.sort(compareByName);
    r.woredas.forEach(w => {
      if (!w.watersheds) {
        return;
      }

      w.watersheds.sort(compareByName);
      w.watersheds.forEach(crw => {
        if (!crw.microWatersheds) {
          return;
        }

        crw.microWatersheds.sort(compareByName);
      });
    });
  });

  return tree;
}

function compareByName(a: BaseDataBase, b: BaseDataBase): number {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  return aName === bName ? 1 : aName > bName ? 1 : -1;
}
