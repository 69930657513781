import { UserEditGuard } from './user-edit.guard';
import { UserResolver } from './user.resolver';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { UserListComponent } from './user-list/user-list.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'users',
                component: UserListComponent,
                canActivate: [UserEditGuard]
            },
            {
                path: 'users/:name',
                component: UserEditorComponent,
                resolve: {
                    user: UserResolver
                },
                canActivate: [UserEditGuard]
            }
        ])
    ],
    exports: [RouterModule]
})
export class UserEditRoutingModule { }
