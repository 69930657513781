import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, publishReplay, refCount, tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthenticationNotification } from './authentication-notification';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {

  constructor(
    private readonly notify: AuthenticationNotification,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    // Upon startup, fetch authentication data from session storage, if any. Do not listen to storage events, though, to enable having
    // different users in different tabs
    const authDataSerialized = sessionStorage.getItem('auth');
    if (authDataSerialized) {
      const authData: AuthenticationResult = JSON.parse(authDataSerialized);
      notify.announceAuthetication(authData);
    }
  }

  authenticate(username: string, password: string) {
    const loginObservable = this.http
      .post<AuthenticationResult>('/login', { username, password })
      .pipe(
        publishReplay(1),
        refCount<AuthenticationResult>()
      );

    loginObservable
      .subscribe(
        auth => {
          this.setAuthData(auth, password);
          this.notify.announceAuthetication(auth);
        },
        () => undefined
      );

    return loginObservable;
  }

  logout() {
    sessionStorage.removeItem('auth');
    this.notify.announceLogout();
  }

  private setAuthData(authData: AuthenticationResult, password: string) {
    sessionStorage.setItem('auth', JSON.stringify(authData));
    sessionStorage.setItem('secret', password);
  }

  get username() {
    return (JSON.parse(sessionStorage.getItem('auth') || '{"username":null}') as AuthenticationResult).username;
  }

  get password() {
    return sessionStorage.getItem('secret');
  }

  ensureAuthenticated(redirect: string, login: boolean = true): Observable<false | AuthenticationResult> {
    return this.notify.notification
      .pipe(
        take(1),
        tap<CustomEvent>(e => {
          if (e.type === AuthenticationNotification.EVENT_TYPE_LOGOUT && login) {
            this.router.navigate(['/', 'login'], { queryParams: { redirect }});
          }
        }),
        map<CustomEvent, false | AuthenticationResult>(
          e => e.type === AuthenticationNotification.EVENT_TYPE_AUTHENTICATED
          && e.detail as AuthenticationResult
        )
      );
  }

  authorize(mask: UserPermissions, permissions: UserPermissions, admin?: boolean | string): boolean {
    return (Object.keys(mask || {}) as (keyof UserPermissions)[])
      .reduce<boolean>(
        (result, permission) =>
          result
          && (
            !mask[permission]
            || permission === (admin === true ? 'admin' : admin)
            || !!permissions[permission]
          ),
        true
      );
  }
}

export interface UserPermissions {
  write?: boolean;
  create?: boolean;
  delete?: boolean;
  admin?: boolean;
}

export interface AuthenticationResult {
  jwt: string;
  username: string;
  permissions: UserPermissions;
}

export interface PermissionMask {
  [permission: string]: boolean;
}
