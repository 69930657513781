import { Benchterrace } from '../../../sriis-types';
import { StructureTypeComponentBase } from '../structure-type-component-base';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'slm-benchterrace',
  templateUrl: './benchterrace.component.html',
  styleUrls: ['./benchterrace.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class BenchterraceComponent implements StructureTypeComponentBase, OnInit {

  model: Benchterrace;

  constructor() { }

  ngOnInit() {
  }

}
